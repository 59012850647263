// Change your color you want --- please input rgb color (ex: 22,33,44)

// THEME PALETTE 9 ==========================================
:root {
  // COLORS DEFAULT
  --c-primary-50: 240, 249, 255;
  --c-primary-100: 224, 242, 254;
  --c-primary-200: 186, 230, 253;
  --c-primary-300: 125, 211, 252;
  --c-primary-400: 56, 189, 248;
  --c-primary-500: 14, 165, 233;
  --c-primary-600: 2, 132, 199;
  --c-primary-700: 3, 105, 161;
  --c-primary-800: 7, 89, 133;
  --c-primary-900: 12, 74, 110;

  // Chilean Independece Day
  // --c-primary-50: 254, 243, 242;
  // --c-primary-100: 254, 228, 226;
  // --c-primary-200: 254, 206, 202;
  // --c-primary-300: 253, 170, 164;
  // --c-primary-400: 249, 122, 112;
  // --c-primary-500: 240, 79, 67;
  // --c-primary-600: 219, 47, 34;
  // --c-primary-700: 186, 38, 27;
  // --c-primary-800: 154, 35, 26;
  // --c-primary-900: 128, 35, 28;
  // --c-primary-950: 69, 14, 10;

  // MOTHER DAY COLORS
  // --c-primary-50: 255, 217, 231;
  // --c-primary-100: 255, 203, 220;
  // --c-primary-200: 251, 187, 208;
  // --c-primary-300: 125, 211, 252;
  // --c-primary-400: 243, 167, 193;
  // --c-primary-500: 235, 140, 174;
  // --c-primary-600: 204, 96, 138;
  // --c-primary-700: 190, 84, 126;
  // --c-primary-800: 177, 72, 114;
  // --c-primary-900: 105, 3, 56;

  // SECONDARY COLOR
  --c-secondary-50: 240, 253, 244;
  --c-secondary-100: 220, 252, 231;
  --c-secondary-200: 187, 247, 208;
  --c-secondary-300: 134, 239, 172;
  --c-secondary-400: 74, 222, 128;
  --c-secondary-500: 34, 197, 94;
  --c-secondary-600: 22, 163, 74;
  --c-secondary-700: 21, 128, 61;
  --c-secondary-800: 22, 101, 52;
  --c-secondary-900: 20, 83, 45;

  // Chilean Independece Day
  // --c-secondary-50: 236, 247, 255;
  // --c-secondary-100: 212, 235, 255;
  // --c-secondary-200: 178, 222, 255;
  // --c-secondary-300: 121, 201, 255;
  // --c-secondary-400: 50, 174, 254;
  // --c-secondary-500: 7, 147, 240;
  // --c-secondary-600: 0, 117, 205;
  // --c-secondary-700: 0, 92, 166;
  // --c-secondary-800: 3, 79, 137;
  // --c-secondary-900: 9, 66, 113;
  // --c-secondary-950: 6, 41, 75;

  // NEUTRAL COLOR
  --c-neutral-50: 249, 250, 251;
  --c-neutral-100: 243, 244, 246;
  --c-neutral-200: 229, 231, 235;
  --c-neutral-300: 209, 213, 219;
  --c-neutral-400: 156, 163, 175;
  --c-neutral-500: 107, 114, 128;
  --c-neutral-600: 75, 85, 99;
  --c-neutral-700: 55, 65, 81;
  --c-neutral-800: 31, 41, 55;
  --c-neutral-900: 17, 24, 39;
}
