@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

html{
  scroll-behavior: smooth;
}

/* html {
  cursor: url(images/cursor/icons8-mother-48.png), auto;
}

html :hover {
  cursor: url(images/cursor/icons8-mother-48.png), pointer;
} */